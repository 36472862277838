<template>
  <div class="layout-topbar">
    <img
      v-if="empresa.logo"
      :src="'data:image/png;base64, ' + empresa.logo"
      width="100"
      height="60"
      alt="Logo.jpg"
    />
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <div class="centro-topbar">
      <div v-if="'Preregistro Crear' in auth.user.permissions">
        <span class="title-text">Pre Registros:</span>
        <Button
          icon="pi pi-bell"
          v-tooltip.right="'Ver Preregistros'"
          :class="[
            'p-button-rounded',
            cantidad_preregistros > 0
              ? 'p-button-danger alert-button animated'
              : 'p-button-warning',
          ]"
          @click="verListadoPrerequisitos"
        />
        <span
          v-tooltip.right="'Ver Preregistros'"
          :class="[
            'p-badge',
            cantidad_preregistros > 0 ? 'p-badge-danger' : 'p-badge-info',
          ]"
          @click="verListadoPrerequisitos"
          style="cursor: pointer"
        >
          {{ cantidad_preregistros }}
        </span>
        <Button
          v-tooltip.right="'Actualizar'"
          @click="obtenerCantidadPreregistros"
          :loading="loading"
          icon="pi pi-refresh"
          class="p-button-rounded p-button-info p-button-text"
          style="cursor: pointer"
        >
        </Button>
      </div>
    </div>

    <!-- Botones de Perfil y Salir -->
    <div class="right-topbar">
      <span class="usuario-name"
        ><strong>Usuario: </strong>{{ user.name }}</span
      >

      <Button
        class="p-button-text p-button-lg p-button-info"
        style="font-weight: bold"
        @click="irAPerfil"
        v-tooltip.bottom="'Perfil'"
      >
        <i class="pi pi-fw pi-user"></i>Perfil
      </Button>

      <Button
        class="p-button-text p-button-lg p-button-danger"
        style="font-weight: bold"
        @click="openNuevo"
        v-tooltip.bottom="'Salir'"
      >
        <i class="pi pi-fw pi-sign-in"></i>Salir
      </Button>
    </div>
  </div>

  <!-- Dialogo de Cerrar Sesión -->
  <Dialog
    v-model:visible="salirDialog"
    :style="{ width: '450px' }"
    header="Cerrar Sesión"
    :modal="true"
    class="p-fluid"
  >
    <h4><strong>Est&aacute;s seguro de Cerrar Sesión?</strong></h4>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-outlined p-button-success mr-2 mb-2 p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="Cerrar Sesión"
        icon="pi pi-check"
        class="p-button-outlined p-button-danger mr-2 mb-2 p-button-lg"
        @click="cerrarSession()"
      />
    </template>
  </Dialog>
</template>

<script>
import { useAuth } from "@/stores";
import EmpresaService from "@/service/EmpresaService";
import ClienteService from "@/service/ClienteService";

export default {
  emits: ["menu-toggle"],
  data() {
    return {
      empresa: {},
      user: {},
      fechaActual: new Date(),
      salirDialog: false,
      cantidad_preregistros: 0,
      loading: false,
    };
  },
  auth: null,
  empresaService: null,
  clienteService: null,
  beforeCreate() {
    this.empresaService = new EmpresaService();
    this.empresaService.getFirstEmpresa().then((response) => {
      this.empresa = response.data;
    });
  },
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    // obtenerCantidadPreregistros que se ejecute cada 30 minutos
    setInterval(() => {
      this.obtenerCantidadPreregistros();
    }, 1800000);
  },
  mounted() {
    this.user = { ...this.auth.user };
  },
  methods: {
    obtenerCantidadPreregistros() {
      this.loading = true;
      this.clienteService
        .getCountPreregistros()
        .then((response) => {
          this.cantidad_preregistros = response.cantidad_preregistros;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    verListadoPrerequisitos() {
      this.$router.push("/preregistros");
    },
    irAPerfil() {
      this.$router.push("/usuario-detalle");
    },
    openNuevo() {
      this.salirDialog = true;
    },
    ocultarDialog() {
      this.salirDialog = false;
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    } /* 
				topbarImage() {
					return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
				}, */,
    cerrarSession() {
      return this.auth.userLogout();
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped>
.centro-topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.estilo-fecha {
  font-size: 1.4rem;
  font-weight: bold;
}

.right-topbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
}

.usuario-name {
  display: none;
}

/* Mostrar nombre de usuario solo en pantallas grandes */
@media (min-width: 1024px) {
  .usuario-name {
    display: inline;
    margin-right: 10px;
  }
}

/* Ajuste de los botones en pantallas móviles */
@media (max-width: 768px) {
  .right-topbar {
    flex-direction: column;
    align-items: flex-end;
  }

  .right-topbar button {
    margin-bottom: 5px;
  }
}
.alert-button {
  animation: shake 1.5s infinite;
  background-color: #ff0000;
  color: white;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.title-text {
  font-size: 1.5em; /* Tamaño más grande */
  font-weight: bold; /* Negrita */
}

.notification-content {
  display: flex;
  align-items: center;
}
.p-badge {
  display: inline-block;
  margin-left: 0.5rem;
}
</style>
