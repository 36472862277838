<template>
  <div class="layout-footer">
    <!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
    por -->
    <!-- <a class="font-medium ml-2" href="https://sitbolivia.com/" target="_blank"
      ><strong> SIT-BOLIVIA VERSI&Oacute;N : 1.0.46</strong></a
    > -->
    <a class="font-medium ml-2" href="https://future.com.bo/" target="_blank"
      ><strong> FUTURE VERSI&Oacute;N : 1.0.0</strong></a
    >
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  /* methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		}, */
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped lang="scss">
.a {
  color: rgb(1, 28, 39);
}
.layout-footer a {
  color: #002236;
  text-decoration: none;
}

.layout-footer a:hover {
  text-decoration: underline;
}
</style>
